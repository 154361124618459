import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTotalPrice from 'hooks/useTotalPrice';

import { Accordion, Container, Row, Col, Image, Card, ListGroup } from 'react-bootstrap';

import StickyAside from 'components/StickyAside';
import RewindButton from 'components/RewindButton';
import Price from 'components/Price';
import { OfferModal, PdfModal, SaveCarConfigModal } from 'components/modals';
import SelectedAccessories from './SelectedAccessories';
import SelectedPackages from './SelectedPackages';
import TireLabels from './TireLabels';
import TechData from './TechData';

import PlaceholderCar from 'assets/images/placeholder-car.png';

const Conclusion = () => { //const state = useSelector(state => state); console.log('state', state);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const totalPrice = useTotalPrice();

  const { builderData } = useSelector(state => state.data);
  const { colorCode, interior } = useSelector(state => state.config);

  const ENV = window.ENV[window.location.hostname] || window.ENV.default;

  const selectedExterior = builderData.exteriors?.find(item => item.code === colorCode);
  const selectedInterior = builderData.interiors?.find(item => item.code === interior);

  useEffect(() => {
    if (!builderData.model) window.location.replace(ENV.FALLBACK_URL);
  }, [builderData, navigate, ENV]);

  return (
      <section className="c-section">
        <Container>
          <Row className="gx-lg-5">
            <Col lg="8">
              <div className="c-vehicle">
                <div className="c-vehicle__body">
                  <div className="c-vehicle__caption c-caption">
                    <h2 className="c-caption__title">{builderData.model.name}</h2>
                    <p className="c-caption__descr">{builderData.name}</p>
                    <p className="c-caption__btn">
                      <RewindButton href="/assemble" text={t('changes_selections')} />
                    </p>
                  </div>
                </div>
                <figure className="c-vehicle__img">
                  <Image src={selectedExterior ? selectedExterior.pictures.main : PlaceholderCar} />
                </figure>
              </div>
              <h5 className="c-section__subtitle">{t('your_selected_accessories')}</h5>
              <ListGroup variant="flush">
                {selectedExterior &&
                <ListGroup.Item>
                  <span className="list-group-label">{selectedExterior.name}</span>
                  <b className="list-group-value"><Price price={selectedExterior.price} placeholder="&ndash;" /></b>
                </ListGroup.Item>
                }
                {selectedInterior &&
                <ListGroup.Item>
                  <span className="list-group-label">{selectedInterior.name}</span>
                  <b className="list-group-value"><Price price={selectedInterior.price} placeholder="&ndash;" /></b>
                </ListGroup.Item>
                }
              </ListGroup>
              <Accordion className="c-accordion" flush>
                <SelectedAccessories />
                <SelectedPackages />
              </Accordion>
              <Card className="c-total">
                <Card.Body className="c-total__body">
                  <Card.Title className="c-total__title">{t('total')}</Card.Title>
                  <Card.Text className="c-total__price">
                    <Price price={totalPrice} />
                  </Card.Text>
                  <Card.Text className="c-total__hint">
                    <b>{t('consent_short_title')}</b><br/>
                    <span>{t('consent_short_text')}</span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Accordion className="c-accordion" flush>
                <TechData />
                <TireLabels />
              </Accordion>
            </Col>
            <Col lg="4" className="c-aside-container">
              <StickyAside />
            </Col>
          </Row>
        </Container>
        <SaveCarConfigModal />
        <OfferModal />
        <PdfModal />
      </section>
  );
};

export default Conclusion;
