import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Accordion, ListGroup } from 'react-bootstrap';

const TechData = () => {
  const { t } = useTranslation();

  const { builderData } = useSelector(state => state.data);
  const { techdata } = builderData;

  if (!techdata) return null;

  const getTechDataAmount = () => {
    return [].concat(...Object.values(techdata)).length;
  };

  return (
    <Accordion.Item className="c-accordion__item" eventKey="accordionTech">
      <Accordion.Button className="c-accordion__toggler">
        {`${t('technical_spec')} (${getTechDataAmount()})`}
      </Accordion.Button>
      <Accordion.Body className="c-accordion__body">
        {Object.keys(techdata).map(category => 
          <ListGroup variant="flush" key={`techCategory-${category}`}>
            <ListGroup.Item as="strong">
              {category}
            </ListGroup.Item>
            {techdata[category].map(item =>
              <ListGroup.Item key={`techItem-${item.code}`}>
                <span className="list-group-label">{item.name}</span>
                <b className="list-group-value">{item.value}</b>
              </ListGroup.Item>
            )}
          </ListGroup>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TechData;
